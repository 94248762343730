/*----- 19. Product details page  ---*/

.product-details-img {
  @media #{$lg-layout} {
    &.mr-20 {
      margin-right: 0;
    }
  }

  @media #{$md-layout} {
    &.mr-20 {
      margin-right: 0;
    }
  }

  @media #{$xs-layout} {
    &.mr-20 {
      margin-right: 0;
    }
  }

  .large-img-style {
    position: relative;

    img {
      width: 100%;
    }

    span {
      font-size: 13px;
      font-weight: 500;
      line-height: 1;

      position: absolute;
      top: 30px;
      left: 30px;

      display: inline-block;

      padding: 3px 11px;

      color: #fff;
      border-radius: 3px;
      background-color: #fa6bff;
    }

    .img-popup-wrap {
      font-size: 30px;

      position: absolute;
      top: 30px;
      right: 30px;

      color: #000000;

      &:hover {
        color: $theme-color;
      }
    }
  }

  .shop-details-tab {
    position: relative;
    z-index: 9;

    display: flex;
    justify-content: center;

    margin-top: -72px;

    @media #{$md-layout} {
      margin-top: -50px;
    }

    @media #{$xs-layout} {
      margin-top: -45px;
    }

    @media #{$sm-layout} {
      margin-top: -60px;
    }

    a.shop-details-overly {
      position: relative;

      img {
        width: 144px;

        @media #{$md-layout} {
          width: 90px;
        }

        @media #{$xs-layout} {
          width: 85px;
        }

        @media #{$sm-layout} {
          width: 110px;
        }
      }

      &.active:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;

        content: "";

        opacity: 0.6;
        background-color: #fff;
      }
    }
  }
}

.description-review-topbar {
  display: flex;
  // justify-content   : space-between;
  // background        : #DCDAEC;
  // border-radius     : 9px;
  // max-width            : 1021px;
  // margin               : 0 auto;

  // padding      : 0px 3px 0 3px;
  border-bottom: 1px solid #dee2e6;

  // border-bottom: 1px solid #d7d7d7;
  .nav-item {
    a {
      // font-weight   : 500;
      // line-height   : 1;
      // // padding    : 0 0 13px;
      // font-style    : normal;
      // font-weight   : 500;
      // font-size     : 16px;
      // line-height   : 162.2%;
      // color         : #3C3C3C;
      // padding       : 10px 55px;

      font-style: normal;
      line-height: 162.2%;
      padding: 10px 55px;
      border-top-left-radius: 0.25rem !important;
      border-top-right-radius: 0.25rem !important;
      background: #f0f0f0;
      margin-right: 0.25rem;
      border: 1px solid #dadada;
      border-bottom: 0;
      font-size: 16px;
      font-weight: 600;
      color: #666;
      text-align: center;
      border-radius: unset;
      margin-bottom: -1px;

      &.active {
        // color        : #fff;
        // background   : #654EA3;
        // border-radius: 9px;

        border-radius: unset;
        background-color: #fff;
        border-color: #dee2e6 #dee2e6 #fff;
        border-top: 2px solid #654EA3;
        color: #654EA3;
        position: relative;

      }

      @media #{$xs-layout} {
        font-size: 15px;

        margin: 0 4px 15px;
        padding: 0 0 8px;
      }

      @media #{$sm-layout} {
        font-size: 18px;

        margin: 0 8px 0 0;
        padding: 0 5px 0;
      }
    }
  }
}

.description-review-bottom {
  // padding   : 33px 30px 0;
  // background: transparent;

  background: transparent;
  width: 100%;
  border-left: 1px solid #e0d9d9;
  border-right: 1px solid #e0d9d9;
  border-bottom: 1px solid #e0d9d9;
  padding: 2rem;
  color: #666;

  .tab-pane {
    font-family: $gothamLightRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 162.2%;
    color: #616160;

    p {
      font-family: $gothamLightRegular;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 162.2%;
      color: #616160;
    }
  }

  .product-description-wrapper {
    p {
      font-size: 15px;
      line-height: 28px;

      width: 84%;
      margin: 0 0 8px;

      color: #3C3C3C;

      @media #{$xs-layout} {
        width: 100%;
      }

      &:last-child {
        margin: 0 0 0;
      }
    }
  }

  .product-anotherinfo-wrapper {
    ul {
      li {
        font-size: 14px;

        margin: 0 0 13px;

        list-style: outside none none;

        color: #333;

        span {
          font-weight: 500;

          display: inline-block;

          min-width: 85px;
          margin: 0 26px 0 0;

          color: #000;
        }
      }
    }
  }

  .download-box {
    a {
      display: -webkit-inline-flex;
      display: inline-flex;
      box-shadow: 0 0 5px rgb(0 0 0 / 10%);
      border-radius: 6px;
      padding: 1rem;
      margin-top: 1rem;
      -webkit-align-items: center;
      align-items: center;
    }

    p {
      font-weight: 700;
      color: #616160;
      font-size: 18px;
      padding-right: 15px;
    }

    .download-icon {
      font-size: 64px;
    }
  }
}

.single-review {
  display: flex;

  margin: 0 0 50px;

  @media #{$xs-layout} {
    display: block;
  }

  @media #{$sm-layout} {
    display: flex;
  }

  .review-img {
    flex: 0 0 90px;

    margin: 0 15px 0 0;

    @media #{$xs-layout} {
      margin: 0 0 20px 0;
    }

    @media #{$sm-layout} {
      margin: 0 15px 0 0;
    }
  }

  .review-top-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0 0 10px;

    .review-left {
      display: flex;

      .review-name {
        margin-right: 25px;

        h4 {
          font-size: 16px;
          font-weight: 500;
          line-height: 1;

          margin: 0;
        }
      }

      .review-rating {
        line-height: 1;

        i {
          font-size: 12px;

          margin-right: 1px;

          color: #ffa900;
        }
      }

      &>button {
        border: none;
        background: none;

        &:hover {
          color: $theme-color;
        }
      }
    }

    .review-btn a {
      font-weight: 500;
      line-height: 1;
    }
  }

  .review-bottom p {
    width: 93%;
    margin: 0;
  }
}

.single-review.child-review {
  margin-bottom: 0;
  margin-left: 70px;

  @media #{$xs-layout} {
    margin-left: 0;
  }
}

.google_ratting_form .modal-dialog {
  width: 600px;
  max-width: 600px;

  h3 {
    font-size: 23px;
    font-weight: 500;
    line-height: 1;
    color: #654EA3;
    text-align: center;
    margin: 0 auto;
  }
}

.ratting-form-wrapper {
  padding: 20px 30px 50px 30px;

  @media #{$md-layout} {
    &.pl-50 {
      margin-top: 50px;
      padding-left: 0;
    }
  }

  @media #{$xs-layout} {
    &.pl-50 {
      margin-top: 50px;
      padding-left: 0;
    }
  }

  span {
    color: #6f6f6f;
    font-size: 16px;
  }

  .rating-form-style {
    span {
      display: block;
      margin-bottom: 10px;
    }
  }

  .ratting-form {
    form {
      .star-box {
        display: flex;
        align-items: center;
        margin: 6px 0 20px;

        span {
          margin: 0 15px 0 0;
        }

        .ratting-star {
          font-size: 12px;

          margin: 2px 0 0;

          color: #ffa900;
        }
      }

      .rating-form-style {

        input,
        textarea {
          padding: 2px 10px 2px 20px;

          color: #333;
          border: 1px solid #e6e6e6;
          background: transparent;
        }

        textarea {
          height: 180px;
          margin-bottom: 20px;
          padding: 20px 10px 2px 20px;
        }
      }

      .form-submit input {
        font-weight: 500;
        width: auto;
        height: auto;
        padding: 12px 50px;
        border-radius: 9px;
        text-transform: uppercase;
        color: #fff;
        border: 1px solid $theme-color;
        background-color: $theme-color;

        &:hover {
          border: 1px solid #F088B6;
          background-color: #F088B6;
        }
      }

      .form-submit button {
        font-weight: 500;
        width: auto;
        height: auto;
        padding: 12px 50px;
        border-radius: 9px;
        text-transform: uppercase;
        color: #fff;
        border: 1px solid $theme-color;
        background-color: $theme-color;

        &:hover {
          border: 1px solid #F088B6;
          background-color: #F088B6;
        }
      }
    }
  }
}

/* product details 2 */

.product-dec-slider-2 {
  display: inline-block;
  float: left;

  width: 20%;
  padding: 0;

  @media #{$lg-layout} {
    width: 19%;
  }

  @media #{$md-layout} {
    margin-bottom: 40px;
  }

  @media #{$xs-layout} {
    width: 30%;
    margin-bottom: 30px;
  }

  .product-dec-icon {
    font-size: 25px;
    line-height: 1;

    position: absolute;
    z-index: 99;
    left: 50%;

    display: inline-block;
    visibility: hidden;

    padding: 0 10px;

    cursor: pointer;
    transform: translateX(-50%);

    opacity: 0;
    color: #666;

    &.product-dec-prev {
      top: -25px;
    }

    &.product-dec-next {
      bottom: -14px;
    }
  }

  &.slick-vertical {
    .slick-slide {
      display: block;

      height: auto;
      margin: 2px 0 10px;

      text-align: center;

      img {
        display: inline-block;

        width: 100%;
      }
    }
  }

  &:hover .product-dec-icon {
    visibility: visible;

    opacity: 1;
  }
}

.zoompro-wrap {
  &.zoompro-2 {
    position: relative;

    display: inline-block;
    float: left;

    width: 80%;

    @media #{$lg-layout} {
      width: 79%;
    }

    @media #{$xs-layout} {
      width: 70%;
      margin-bottom: 30px;
    }
  }

  .zoompro-span {
    position: relative;

    span {
      font-size: 13px;
      font-weight: 500;
      line-height: 1;

      position: absolute;
      z-index: 99;
      top: 30px;
      left: 30px;

      display: inline-block;

      padding: 3px 11px;

      color: #fff;
      border-radius: 3px;
      background-color: #fa6bff;
    }

    .product-video {
      position: absolute;
      z-index: 99;
      bottom: 30px;
      left: 30px;

      text-align: center;

      a {
        display: inline-block;

        padding: 5px 15px 5px;

        color: #fff;
        border-radius: 3px;
        background-color: #fa6bff;

        @media #{$xs-layout} {
          padding: 3px 10px 3px;
        }
      }
    }

    img {
      width: 100%;
    }
  }
}

.dec-img-wrap {
  position: relative;

  img {
    width: 100%;
  }

  span {
    font-size: 13px;
    font-weight: 500;
    line-height: 1;

    position: absolute;
    z-index: 99;
    top: 30px;
    left: 30px;

    display: inline-block;

    padding: 3px 11px;

    color: #fff;
    border-radius: 3px;
    background-color: #fa6bff;
  }
}

.sidebar-active {
  @media #{$xs-layout} {
    & .product-details-content {
      margin-top: 0;
    }
  }

  &.col-lg-6 {
    padding-right: 15px;
    padding-left: 15px;

    &.is-affixed {
      padding-right: 0;
      padding-left: 0;

      & .inner-wrapper-sticky {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
}

.product-details-gallery {
  @media #{$md-layout} {
    &.mr-20 {
      margin-right: 0;
    }
  }

  @media #{$xs-layout} {
    &.mr-20 {
      margin-right: 0;
    }
  }
}

/* affiliate */

.pro-details-affiliate {
  margin: 30px 0;

  a {
    font-weight: 500;
    line-height: 1;

    z-index: 9;

    display: inline-block;

    padding: 13px 30px 16px;

    color: #fff;
    border: 1px solid transparent;
    background-color: #000;
  }
}

/* product-details-slider */

.product-details-slider-active {
  &.nav-style-1.owl-carousel {
    .owl-nav {
      div {
        font-size: 20px;

        left: 20px;

        color: #333;

        &:hover {
          color: $theme-color;
        }

        &.owl-next {
          right: 20px;
          left: auto;
        }
      }
    }
  }

  &:hover.nav-style-1.owl-carousel>.owl-nav div {
    visibility: visible;

    opacity: 1;
  }
}

.shop-area {
  margin-top: 30px;
}

.carousel.carousel-slider {
  margin-bottom: 35px;
}

button.write-actions-newreview {
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #666;
}

ul.control-dots {
  display: none;
}

p.carousel-status {
  display: none;
}

.product-large-image-wrapper {
  position: relative;
  margin-bottom: 60px;

  .overview-slider {
    position: relative;
    padding: 1rem 0;

    //   min-height: 500px;
    // width: 50%;
    // overflow: scroll;
    .image-gallery-thumbnails-wrapper {
      width: 150px;

      // @media #{$sm} {
      //   display: none;
      // }

      .image-gallery-thumbnails-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .image-gallery-thumbnail {
          width: 100%;
          //   height: 30%;
          margin-bottom: 0.75rem;
          padding: 0 0.25rem;
        }
      }
    }

    .image-gallery-slide-wrapper.left,
    .image-gallery-slide-wrapper.right {
      display: inline-block;
      width: calc(100% - 160px);

      // @media #{$sm} {
      //   width: 100%;
      // }
    }

    .thumbnail-override {
      height: 33.3%;
      width: 100%;
      //   overflow: hidden;
      border: none;

      // &.thumbnail-video{
      //   content: '\f04b';
      //   position: absolute;
      //   top: 0;
      //   width: 100%;
      //   height: 100%;
      // }
      &.active {
        .image-gallery-thumbnail-inner {
          border: 1px solid #5a4692;
        }
      }

      .image-gallery-thumbnail-inner {
        box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.2);
        height: 100%;
        border-radius: 6px;

        .image-gallery-thumbnail-image {
          object-fit: cover;
          object-position: center;
          border-radius: 8px;
          box-shadow: 0 4px 10px #aaa;
        }
      }
    }

    .ctrl-button {
      display: inline-block;
      //   width: 40px;
      //   height: 47px;
      position: absolute;
      z-index: +1;
      background: transparent;
      border: none;
      padding: 0;
      text-indent: -9999px;
      transition: all 0.3s ease;
      outline: none;
      color: #5a4692;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: #fff;
        //   box-shadow: 2.457px 1.721px 6px 0px rgb(0 0 0 / 30%);
        box-shadow: 0 0 10px #ccc;
        transition: all 0.3s ease;
      }

      .button-svg {
        width: 11px;
        height: 19px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 10.5px;
        height: 19px;
        display: block;
        margin: auto;
        // background     : url(./../../../assets/icons/i_arrow_red.svg) center no-repeat;
        background-size: 100% auto;
      }

      &.prev {
        left: calc(70px);
        top: calc(-35px + 1rem);

        &::before {
          border-radius: 59% 41% 48% 52% / 55% 39% 61% 45%;
        }

        .button-svg {
          -webkit-transform: rotateZ(180deg);
          -ms-transform: rotate(180deg);
          transform: rotateZ(180deg);
        }
      }

      &.next {
        left: calc(70px);
        bottom: calc(-35px + 1rem);

        &::before {
          border-radius: 41% 59% 52% 48% / 39% 55% 45% 61%;
        }
      }

      // @media #{$sm} {

      //   &.prev,
      //   &.next {
      //     left: calc(50% - 16px);
      //   }
      // }
    }
  }

  .product-img-badges {
    position: absolute;
    z-index: 3;
    top: 20px;
    left: 20px;

    span {
      font-size: 13px;
      font-weight: 500;
      line-height: 1;

      display: block;

      margin-bottom: 10px;
      padding: 3px 11px;

      color: #fff;
      border-radius: 3px;

      &.pink {
        background-color: #fa6bff;
      }

      &.purple {
        background-color: #654EA3;
      }
    }
  }

  .react_lightgallery_item {
    position: absolute;
    top: 20px;
    right: 20px;

    button {
      font-size: 30px;

      padding: 0;

      border: none;
      background: none;
    }
  }

  &--slider {
    .ht-swiper-button-nav {
      font-size: 50px;

      visibility: hidden;

      padding: 0;

      transition: 0.3s;

      opacity: 0;
      color: #333;
      border: none;
      background: none;
    }

    &:hover {
      .ht-swiper-button-nav {
        visibility: visible;

        opacity: 1;
      }
    }
  }

  .single-image {
    img {
      width: 100%;
    }
  }
}

.shop-area {
  .sticky {
    top: 70px !important;
  }
}

.product-small-image-wrapper {
  .ht-swiper-button-nav {
    visibility: hidden;

    opacity: 0;
    color: #333;
    border: none;
    background: none;

    i {
      font-size: 40px;
    }
  }

  .single-image {
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  &:hover {
    .ht-swiper-button-nav {
      visibility: visible;

      opacity: 1;
    }
  }

  .single-image {
    height: 100%;

    img {
      height: 100%;

      object-fit: cover;
      object-position: center;
    }
  }

  .swiper-container-vertical {
    height: 100%;
  }

  &--side-thumb {
    overflow: hidden;

    // height: 630px;
    @media #{$lg-layout,
 $md-layout,
    $xs-layout
  }

    {
    height: auto;
    margin-top: 15px;
  }
}
}

.related-product-area {
  .section-title {
    h2 {
      color: #654EA3;
      font-size: 48px;
      line-height: 53px;
      /* identical to box height */
      text-align: center;
      text-transform: capitalize;

      @media #{$xs-layout} {
        font-size: 32px;
        line-height: 32px;
      }

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }

  .swiper-container {
    width: 100%;
  }
}

.spr-summary-actions-newreview_col {
  // text-align: right;
  padding-bottom: 50px;
}

.pro-details-cart a.disabel {
  opacity: 0.5;
  pointer-events: none;
}

button.spr-summary-actions-newreview {
  font-size: 16px;
  line-height: 1;
  display: inline-block;
  padding: 13px 33px 13px;
  text-transform: capitalize;
  color: #fff;
  background-color: #654EA3;
  border: none;
  border-radius: 9px;
  font-weight: 900;
}

.product-large-image-wrapper .overview-slider .image-gallery-thumbnails-wrapper {
  @media #{$xs-layout} {
    width: 100px;
  }

  @media #{$md-layout} {
    width: 100px;
  }
}

.product-large-image-wrapper .overview-slider .ctrl-button.next {
  @media #{$xs-layout} {
    left: calc(35px);
  }

  @media #{$md-layout} {
    left: calc(35px);
  }
}

.product-large-image-wrapper .overview-slider .ctrl-button.prev {
  @media #{$xs-layout} {
    left: calc(35px);
  }

  @media #{$md-layout} {
    left: calc(35px);
  }
}

.description-review-area {
  @media #{$xs-layout} {
    margin-top: 30px;
  }
}

.listingStyle {
  ul {
    list-style-type: disc;
    margin-bottom: 15px !important;
    padding-left: 30px !important;
  }
}