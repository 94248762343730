/*------ 22. contact Page  ------*/

.contact-map {
  position: relative;

  height: 560px;

  @media #{$xs-layout} {
    height: auto;
  }

  .mapouter {
    .gmap_canvas {
      iframe {
        @media #{$xs-layout} {
          height: auto;
          width: 100%;
        }
      }
    }
  }
}

.contactmbldiv {
  @media #{$xs-layout} {
    margin-bottom: 20px;
  }
}
.contact-info-wrap {
  padding: 50px;
  border-radius: 30px;
  background-color: #f6f6f6;
  height: 97%;

  @media #{$lg-layout} {
    padding: 40px;
  }

  @media #{$md-layout} {
    padding: 30px;
  }

  @media #{$xs-layout} {
    padding: 30px;
    height: 100%;
  }

  .single-contact-info {
    // display: flex;
    // align-items: center;

    // margin-bottom: 40px;

    .contact-icon {
      margin-bottom: 20px;

      i {
        font-size: 20px;
        line-height: 40px;

        display: inline-block;

        width: 40px;
        height: 40px;

        transition: all 0.3s ease 0s;
        text-align: center;

        color: #654ea3;
        border: 1px solid #654ea3;
        border-radius: 100%;
      }
    }

    .contact-info-dec {
      h3 {
        color: #3c3c3c;
      }

      p {
        line-height: 1;
        font-size: 15px;
        color: #3c3c3c;
        line-height: 25.95px;

        a {
          color: #3c3c3c;

          &:hover {
            color: $theme-color;
          }
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    &:hover .contact-icon i {
      color: #fff;
      background-color: #654ea3;
    }
  }
}

.contact-social {
  margin-top: 58px;

  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;

    margin: 0 0 17px;

    color: #4d4d4d;
  }

  ul {
    li {
      display: inline-block;

      margin: 0 10px;

      a {
        font-size: 16px;

        color: #4d4d4d;

        &:hover {
          color: $theme-color;
        }
      }
    }
  }
}

.contact-form {
  padding: 50px 110px 50px 110px;
  border-radius: 30px;
  background-color: #f6f6f6;
  height: 100%;

  @media #{$lg-layout} {
    padding: 50px 50px 50px 50px;
  }

  @media #{$md-layout} {
    padding: 50px 30px 50px 30px;
  }

  @media #{$xs-layout} {
    padding: 50px 30px 50px 30px;
  }

  .contact-title {
    h1 {
      font-weight: 500;
      line-height: 1;
      margin-bottom: 36px;
      color: #3c3c3c;
    }
  }

  .contact-form-style {
    input,
    textarea {
      height: 40px;
      margin-bottom: 30px;
      padding: 2px 14px;
      color: #3c3c3c;
      background: #fff;
      border: 1px solid #b3a8d9;
      box-sizing: border-box;
      border-radius: 9px;
    }

    textarea {
      height: 120px;
      margin-bottom: 0;
      padding: 20px 14px;
    }

    button {
      font-size: 16px;
      font-weight: 500;
      line-height: 1;

      margin-top: 38px;
      padding: 15px 52px;

      text-transform: uppercase;

      color: #fff;
      border: none;
      background: #654ea3;
      border-radius: 9px;

      &:hover {
        background-color: #ed59a0;
      }
    }
  }

  p {
    color: #3c3c3c;

    &.success {
      margin-top: 10px;
    }
  }
}

.contact-form-style {
  .row {
    & div[class^="col-"] {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.contact-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}
