/*------ 16. Breadcrumb style  --------*/
.breadcrumb-area {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 700px;

  @media #{$xs-layout} {
    height: 200px;
    // padding-bottom: 60px;
    padding: 15px 0;
  }

  @media #{$md-layout} {
    height: auto;
    padding-bottom: 60px;
    padding-top: 90px;
  }
}

.breadcrumb_for_detailpage .breadcrumb-content {
  display: none;
}

.breadcrumb-content {
  .inner-banner-content {
    background: rgba(255, 255, 255, 0.7);
    padding: 30px;
    max-width: max-content;
    border-radius: 9px;

    @media #{$xs-layout} {
      padding: 15px;
    }

    h2 {
      color: #fff;
      margin-bottom: 0;

    }

    p.sub_content {
      color: #fff;
      margin-top: 15px;
      margin-bottom: 0;

      @media #{$xs-layout} {
        margin-top: 10px;
      }
    }

    a {
      font-size: 16px;
      line-height: 1;
      display: inline-block;
      padding: 13px 33px 13px;
      text-transform: uppercase;
      color: #fff;
      background-color: #654EA3;
      border-radius: 9px;
      margin-top: 10px;

      &:hover {
        background-color: #ed59a0 !important;
      }
    }
  }

  .multi_color {
    h2 {
      color: #654EA3 !important;

      @media #{$xs-layout} {
        font-size: 20px;
        line-height: 32px;
      }

      @media #{$md-layout} {
        font-size: 24px;
        line-height: 32px;
      }
    }

    p.sub_content {
      color: #6f6f6f;

      @media #{$xs-layout} {
        font-size: 13px;
        line-height: 28px;
      }
    }
  }

  .fontSize-big {
    font-size: 36px;
  }

  .subtitlefontSize-big {
    font-size: 20px;
    line-height: 32px;
  }

  /* Lansinoh Custom Style */
  .multi_color2 {
    h2 {
      color: #3C3C3C;
    }

    p.sub_content {
      color: #3C3C3C;
    }
  }

  .multi_color3 {
    h2 {
      color: #654EA3;
    }

    p.sub_content {
      color: #654EA3;
    }
  }

  p.sub_content.subtitlecolor_color {
    color: #fff !important;
  }

  /* Lansinoh Custom Style End */

  ul {
    li {
      display: inline-block;
      font-size: 15px;
      font-weight: 500;
      color: #5b5858;
      margin-right: 18px;
      text-transform: uppercase;
      position: relative;

      &::before {
        position: absolute;
        width: 18px;
        height: 1px;
        background-color: #5b5858;
        content: "";
        right: -21px;
        top: 12px;
        z-index: 99;
        transform: rotate(115deg);
      }

      &:last-child::before {
        display: none;
      }

      a {
        color: #5b5858;

        &:hover {
          color: $theme-color;
        }
      }
    }
  }

  span {
    &>span {
      display: inline-block;
      font-size: 15px;
      font-weight: 500;
      text-transform: uppercase;

      @media #{$xs-layout} {
        text-transform: capitalize;
      }

      &>a {
        font-weight: 500;
        color: #5b5858;

        &:hover {
          color: $theme-color;
        }
      }

      &>span {
        margin-right: 0;
        margin: 0 15px;
      }
    }
  }
}

.disable {
  display: none !important;
}