/*------- 11. About style ------ */

.welcome-content {
  h5 {
    font-weight: 500;
    font-size: 18px;
    color: #3C3C3C;
    margin: 0;
    margin-bottom: 20px;
    letter-spacing: 0.3px;
  }

  h1 {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.3px;
    margin: 8px 0 0px;
    color: #654EA3;
    position: relative;
    display: inline-block;
    padding: 0 0 23px;
    line-height: 1;
    // &:before {
    //   position: absolute;
    //   background-color: #070508;
    //   height: 3px;
    //   width: 70px;
    //   content: "";
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   margin: 0 auto;
    // }
  }

  p {
    font-size: 16px;
    color: #3C3C3C;
    line-height: 30px;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 15px;

    @media #{$lg-layout} {
      width: 80%;
    }

    @media #{$md-layout} {
      width: 95%;
    }

    @media #{$xs-layout} {
      width: 100%;
      font-size: 15px;
    }
  }
}

/* about page */

.single-count {
  .count-icon {
    i {
      font-size: 50px;
      line-height: 1;
      display: inline-block;
      color: #654EA3;
      background: #FFFFFF;
      border-radius: 67px;
      padding: 25px;
      font-weight: 600;

      @media #{$md-layout} {
        font-size: 45px;
      }

      @media #{$xs-layout} {
        font-size: 45px;
      }
    }
  }

  h2 {
    &>span {
      color: #fff;
      font-size: 40px;
      font-weight: bold;
      margin: 25px 0 12px;
      display: inline-block;

      @media #{$md-layout} {
        margin: 10px 0 8px;
        font-size: 35px;
      }

      @media #{$xs-layout} {
        margin: 6px 0 8px;
        font-size: 35px;
      }
    }
  }

  span {
    color: #fff;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 500;
  }
}

.team-wrapper {
  background-color: #f7f7f7;

  .team-img {
    position: relative;

    img {
      width: 100%;
    }

    .team-action {
      background: #fff none repeat scroll 0 0;
      left: 0;
      opacity: 0;
      padding: 10px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 70%;
      transform: translateY(-50%);
      transition: all 0.6s ease 0s;
      width: 100%;
      z-index: 5;

      a {
        border-radius: 50px;
        color: #fff;
        display: inline-block;
        font-size: 14px;
        height: 30px;
        line-height: 28px;
        text-align: center;
        width: 30px;
        margin: 0 3px;

        &.facebook {
          border: 1px solid #654EA3;
          background-color: #654EA3;

          &:hover {
            background-color: transparent;
            color: #654EA3;
          }
        }

        &.twitter {
          border: 1px solid #55acee;
          background-color: #55acee;

          &:hover {
            background-color: transparent;
            color: #55acee;
          }
        }

        &.instagram {
          border: 1px solid #654EA3;
          background-color: #654EA3;

          &:hover {
            background-color: transparent;
            color: #654EA3;
          }
        }
      }
    }
  }

  .team-content {
    padding: 20px 10px 23px;

    h4 {
      font-size: 18px;
      font-weight: 500;
      margin: 0 0 3px;
      text-transform: capitalize;
    }

    span {
      font-size: 15px;
      font-style: italic;
    }
  }

  &:hover .team-action {
    top: 50%;
    opacity: 1;
  }
}

.single-mission {
  h3 {
    font-size: 25px;
    font-weight: 500;
    margin: 0 0 20px;
    text-transform: capitalize;
    line-height: 22px;
    color: #654EA3;
    font-family: $photoshoot;

    @media #{$xs-layout} {
      margin: 0 0 10px;
    }
  }

  p {
    margin: 0;
  }
}

.about-brand-logo {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}

.happy-tummy-club-form {
  .htc-form {
    padding: 60px;
    background: #F6F6F6;
    border-radius: 10px;

    h2 {
      margin-bottom: 20px;
      color: #3C3C3C;
    }

    p {
      color: #3C3C3C;
    }

    form.contact-form-style {

      input[type="email"],
      input[type="date"] {
        color: #3C3C3C;
        border-bottom: 2px solid #e2e2e2;
        background: #fff;
        border: 1px solid #B3A8D9;
        border-radius: 9px;
        height: 50px;
        padding: 5px 20px;
        margin-bottom: 15px;
      }

      input[type="checkbox"] {
        width: auto;
        float: left;
      }

      label {
        display: block;
        padding-left: 25px;
      }

      button {
        font-size: 16px;
        line-height: 1;
        display: inline-block;
        padding: 13px 50px 13px;
        text-transform: uppercase;
        color: #fff;
        background-color: #654EA3;
        border-radius: 9px;
        border: none;
        margin-top: 50px;

        &:hover {
          background-color: #ed59a0 !important;
        }
      }
    }

  }
}

.feed-with-confidence {
  .fwc-main {
    h3 {
      color: #654EA3;
      font-family: $photoshoot;

      @media #{$xs-layout} {
        line-height: 52px;
      }
    }

    p {
      color: #3C3C3C;
    }

    a {
      color: #654EA3;
      text-transform: uppercase;
      display: inline-block;
    }
  }
}

.fwc-box {
  .fwc-box-inner {
    margin-bottom: 30px;
    position: relative;

    img {
      width: 100%;
    }

    .fwc-box-inner-title {

      // background         : linear-gradient(90deg, #654EA3 0.6%, rgba(101, 78, 163, 0.6) 100%), #FFFFFF;
      // box-sizing         : border-box;
      padding: 12px 5px;
      background-size: cover;
      background-position: center;
      position: absolute;
      bottom: 0;
      width: 100%;

      h3 {
        font-size: 18px;
        color: #fff;
        font-family: $omnesRegular;
        font-weight: normal;
        margin-bottom: 0;

        a {
          color: #fff;
        }
      }
    }
  }
}

.sustainability-community {
  .sac-main {
    h3 {
      color: #654EA3;
      font-family: $photoshoot;
      line-height: 52px;
    }

    p {
      color: #3C3C3C;
    }

    a {
      color: #654EA3;
      text-transform: uppercase;
      display: inline-block;
    }
  }
}

.Our-Sustainable-Development-main {
  background-repeat: no-repeat !important;
  background-size: cover !important;

  .osd-box {
    background: #DCDAEC;

    @media #{$xs-layout} {
      padding: 30px;
    }

    h2 {
      color: #3C3C3C;
      margin-bottom: 30px;
    }

    p {
      color: #3C3C3C;
    }
  }
}

.osdg-boxes {

  .col-xs-12 {
    @media #{$xs-layout} {
      padding-bottom: 30px;
    }
  }

}

.Our-Sustainability-Journey-main {
  background-repeat: no-repeat !important;
  background-size: cover !important;

  .osj-box {
    background: #DCDAEC;

    @media #{$xs-layout} {
      padding: 30px;
    }

    h2 {
      color: #3C3C3C;
      margin-bottom: 30px;
    }

    p {
      color: #3C3C3C;
    }
  }
}

.osdg-boxes {
  .flex_row {
    justify-content: center;
    padding-top: 30px;
  }

  .osdg-box-inner {
    &::hover {
      img {
        &::first-child {
          display: block;
        }

      }
    }
  }
}

.osdg-box-inner img {
  cursor: pointer;
}

.osdg-box-inner img:first-child {
  display: none;
}

.osdg-box-inner:hover img:first-child {
  display: block;
}

.osdg-box-inner:hover img:last-child {
  display: none;
}

.our-story-area {
  padding: 8rem 0;
  margin-bottom: 50px;
  background-size: cover;
  background-repeat: no-repeat;

  @media #{$xs-layout} {
    padding: 15px 10px;
    background-position: center center;
  }

  @media #{$md-layout} {
    padding: 4rem 0;
  }
}

.our-story-backgroundcontent {
  max-width: 425px;
  padding: 35px 20px;
  background: rgba(255, 255, 255, 0.8);
}

.our-story-content {
  margin-bottom: 50px;
  width: 95%;
}

.our_story_video {
  iframe {
    @media #{$xs-layout} {
      width: 100%;
      height: auto;
    }

    @media #{$md-layout} {
      width: 100%;
      height: 300px;
    }
  }
}